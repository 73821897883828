@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("../node_modules/highlight.js/styles/atom-one-dark.css");

/* rubik-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/rubik-v26-latin-300.eot"); /* IE9 Compat Modes */
  src: url("../public/fonts/rubik-v26-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/rubik-v26-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/rubik-v26-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/rubik-v26-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v26-latin-300.svg#Rubik") format("svg"); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/rubik-v26-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("../public/fonts/rubik-v26-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/rubik-v26-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/rubik-v26-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/rubik-v26-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v26-latin-regular.svg#Rubik") format("svg"); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/rubik-v26-latin-500.eot"); /* IE9 Compat Modes */
  src: url("../public/fonts/rubik-v26-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/rubik-v26-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/rubik-v26-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/rubik-v26-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v26-latin-500.svg#Rubik") format("svg"); /* Legacy iOS */
}
/* rubik-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/rubik-v26-latin-700.eot"); /* IE9 Compat Modes */
  src: url("../public/fonts/rubik-v26-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/rubik-v26-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/rubik-v26-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/rubik-v26-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v26-latin-700.svg#Rubik") format("svg"); /* Legacy iOS */
}
/* rubik-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  src: url("../public/fonts/rubik-v26-latin-900.eot"); /* IE9 Compat Modes */
  src: url("../public/fonts/rubik-v26-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/rubik-v26-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/rubik-v26-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/rubik-v26-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/rubik-v26-latin-900.svg#Rubik") format("svg"); /* Legacy iOS */
}

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
}
